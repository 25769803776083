import React, { useEffect, useState } from 'react';
import './App.css';
import { useParams } from "react-router-dom";
import { API_URL } from './config';

export default function DocuSign() {
    const { id } = useParams();
    const [ state, setState ] = useState({
        url:null,
        error:null
    });
    useEffect(() => {
        fetch(`${API_URL}/public/docusign-consent/${id}`)
            .then(response => response.json())
            .then(data => {
                if(data.success){
                    setState(prevState=>({
                        url:data.consent_url
                    }));
                }else{
                    setState(prevState=>({
                        error:true,
                        message:data.message,
                    }));
                }
            });
    }, []);
    if(state.error){
        return <div>{state.message}</div>;
    }
    return <iframe src={state.url} />;
}